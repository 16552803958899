import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AutoCompleteSelect from "../../../../../../components/AutoCompleteSelect/AutoComplete";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import Loader from "../../../../../../components/Loader/Loader";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import { useEnquiryQuery } from "../../../../../../services/CarServices";
import moment from "moment";
import {
  useFinalSubmitMutation,
  useFinancerMasterQuery,
  useGetPreviousInsurerMasterQuery,
  useProposalEnquiry,
} from "../../../../../../services/ProposalServices";
import {
  allowRegistrationNumber,
  noSpace,
  noSpecialExceptHyphen,
  uppercase,
} from "../../../../../../utils/onInput";
import { yesNoItems } from "../../../../../../utils/YesNoRadioItems";
import {
  InputWrapper,
  SummaryDetailsWrapper,
} from "../../../../ProposalForm.style";
import Summary from "../../../Summary/Summary";
import ClosedForm from "../ClosedForm/ClosedForm";
import vehicleDetailsSchema from "./validation";

const VehicleDetails = () => {
  const { proposalData, activeIndex } = useSelector((state) => state.proposal);

  const [formData, setFormData] = useState({
    registration_number: "",
    is_financed: "N",
  });
  const {
    finalSubmit,
    data,
    isLoading: isProposalSubmitting,
  } = useFinalSubmitMutation();
  const [errors, setErrors] = useState();
  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...proposalData }));
  }, [proposalData]);
  const onChange = async (name, data) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
    vehicleDetailsSchema
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };
  const onSubmit = (onSuccess = () => {}) => {
    vehicleDetailsSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...proposalData, ...formData });
      })
      .catch((error) => {
        error?.inner?.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };
  const { product, vehicle } = useProposalEnquiry();
  const { previous_insurer, isLoading } = useGetPreviousInsurerMasterQuery(
    product?.INSURANCE_SLUG
  );
  const isOD = product?.POLICY_TYPE === "OD";

  useEffect(() => {
    onChange("isOD", isOD);
  }, [isOD]);

  useEffect(() => {
    onChange(
      "tp_existing_exp_date",
      moment(vehicle?.registration_date, "YYYY-MM-DD")
        .add(3, "years")
        .subtract(1, "days")
        .format("YYYY-MM-DD")
    );
  }, [vehicle?.registration_date]);

  useEffect(() => {
    if (!formData?.registration_number && vehicle) {
      onChange("registration_number", vehicle.rto_number + "-");
    }
  }, [vehicle]);
  const { financer } = useFinancerMasterQuery(product?.INSURANCE_SLUG);

  const SummaryDetails = (
    <>
      <SummaryDetailsWrapper>
        <Summary
          label='Registration Number'
          value={formData.registration_number}
        />

        <Summary label='Engine Number' value={formData.engine_number} />
        <Summary label='Chassis Number' value={formData.chassis_number} />
        <Summary
          label='Previous Insurer'
          value={
            previous_insurer?.find(
              (item) => item.OPTION_KEY === formData?.prev_insurer_val
            )?.OPTION_VAL
          }
        />
        <Summary label='Previous Policy No.' value={formData.prev_policy_no} />
        {isOD && (
          <>
            <Summary
              label='TP Insurer'
              value={
                previous_insurer?.find(
                  (item) => item.OPTION_KEY === formData?.tp_prev_insurer_val
                )?.OPTION_VAL
              }
            />
            <Summary label='TP Policy No.' value={formData.tp_prev_policy_no} />
            <Summary
              label='TP Policy End Date'
              value={formData.tp_prev_policy_no}
            />
          </>
        )}
        <Summary
          label='Is Car on Loan'
          value={formData.is_financed === "Y" ? "Yes" : "No"}
        />
        {formData.is_financed === "Y" && (
          <Summary label='Financier Name' value={formData.financier_name} />
        )}
      </SummaryDetailsWrapper>
    </>
  );
  return (
    <>
      {isProposalSubmitting && <Loader />}
      <ClosedForm
        title={
          proposalData?.owner_type === "COMP"
            ? "3. Vehicle Details"
            : "4. Vehicle Details"
        }
        next={
         ( product?.INSURANCE_SLUG === "godigit" || "icici_lombard_general")
            ? "CKYC Details"
            : "Final Submit"
        }
        index={proposalData?.owner_type === "COMP" ? 2 : 3}
        onSubmit={onSubmit}
        finalSubmit={
          (product?.INSURANCE_SLUG === "godigit" || "icici_lombard_general") ? () => {} : finalSubmit
        } 
      >
        {activeIndex > (proposalData?.owner_type === "COMP" ? 2 : 3) ? (
          SummaryDetails
        ) : (
          <>
            <InputWrapper>
              <TextInput
                label='Vehicle Registration Number'
                placeholder='MH-02-BX-0323'
                size='medium'
                maxLength={14}
                fixedPart={vehicle?.rto_number + "-"}
                onChange={(e) =>
                  onChange("registration_number", e.target.value)
                }
                onInput={allowRegistrationNumber}
                value={formData?.registration_number}
                error={errors?.registration_number}
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                label='Engine Number'
                size='medium'
                onInput={(e) => {
                  // uppercase(e);
                  noSpecialExceptHyphen(e);
                }}
                maxLength={17}
                onChange={(e) => onChange("engine_number", e.target.value)}
                value={formData?.engine_number}
                error={errors?.engine_number}
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                label='Chasis Number'
                size='medium'
                onInput={(e) => {
                  // uppercase(e);
                  noSpecialExceptHyphen(e);
                }}
                maxLength={25}
                onChange={(e) => onChange("chassis_number", e.target.value)}
                value={formData?.chassis_number}
                error={errors?.chassis_number}
              />
            </InputWrapper>
            <InputWrapper>
              <AutoCompleteSelect
                size='medium'
                _key={"OPTION_KEY"}
                options={previous_insurer || []}
                getOptionLabel={(item) => item.OPTION_VAL || ""}
                label={"Select your previous policy insurer"}
                selectedValue={
                  formData?.prev_insurer_val && {
                    OPTION_KEY: formData?.prev_insurer_val,
                    OPTION_VAL:
                      formData?.prev_insurer_val &&
                      previous_insurer?.find(
                        (item) => item.OPTION_KEY === formData?.prev_insurer_val
                      )?.OPTION_VAL,
                  }
                }
                getOptionSelected={(item, value) =>
                  item?.OPTION_KEY === value?.OPTION_KEY
                }
                handleChange={(val) => {
                  if (val?.OPTION_KEY && val?.OPTION_VAL) {
                    onChange("prev_insurer_val", val.OPTION_KEY);
                    onChange("prev_insurer_name", val.OPTION_VAL);
                  } else {
                    console.log(previous_insurer,"previous_insurerprevious_insurer")
                    console.error("Invalid option selected:", val);
                  }
                }}
                
                error={errors?.prev_insurer_val}
              />
            </InputWrapper>
            
            <InputWrapper>
              <TextInput
                size='medium'
                label={"Previous Policy Number"}
                onChange={(val) => onChange("prev_policy_no", val.target.value)}
                error={errors?.prev_policy_no}
                value={formData?.prev_policy_no}
              />
            </InputWrapper>
            {isOD && (
              <>
                <InputWrapper>
                  <AutoCompleteSelect
                    size='medium'
                    _key={"OPTION_KEY"}
                    options={previous_insurer || []}
                    getOptionLabel={(item) => item.OPTION_VAL || ""}
                    label={"Select your TP policy insurer"}
                    selectedValue={
                      formData?.tp_prev_insurer_val && {
                        OPTION_KEY: formData?.tp_prev_insurer_val,
                        OPTION_VAL:
                          formData?.tp_prev_insurer_val &&
                          previous_insurer?.find(
                            (item) =>
                              item.OPTION_KEY === formData?.tp_prev_insurer_val
                          )?.OPTION_VAL,
                      }
                    }
                    getOptionSelected={(item, value) =>
                      item?.OPTION_KEY === value?.OPTION_KEY
                    }
                    handleChange={(val) => {
                      onChange("tp_prev_insurer_val", val.OPTION_KEY);
                      onChange("tp_prev_insurer_name", val.OPTION_VAL);
                    }}
                    error={errors?.tp_prev_insurer_val}
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput
                    size='medium'
                    label={"TP Policy Number"}
                    onChange={(val) =>
                      onChange("tp_prev_policy_no", val.target.value)
                    }
                    error={errors?.tp_prev_policy_no}
                    value={formData?.tp_prev_policy_no}
                  />
                </InputWrapper>
                <InputWrapper>
                  <DatePicker
                    label={"TP end date"}
                    size='medium'
                    views={["day", "month", "year"]}
                    minDate={moment(
                      moment(vehicle?.registration_date, "YYYY-MM-DD")
                        .add(3, "years")
                        .subtract(1, "days")
                        .subtract(45, "days")
                    )}
                    maxDate={moment(
                      moment(vehicle?.registration_date, "YYYY-MM-DD")
                        .add(3, "years")
                        .subtract(1, "days")
                        .add(45, "days")
                    )}
                    handleChange={(val) => {
                      onChange("tp_existing_exp_date", val);
                    }}
                    openTo='day'
                    outputFormat='YYYY-MM-DD'
                    value={formData?.tp_existing_exp_date}
                    error={errors?.tp_existing_exp_date}
                  />
                </InputWrapper>
              </>
            )}
            <InputWrapper>
              <RadioButton
                items={yesNoItems}
                label='Is Your Car Currently on Loan?'
                onChange={(val) => {
                  onChange("is_financed", val);
                }}
                defaultValue={formData?.is_financed}
              />
            </InputWrapper>

            {formData?.is_financed === "Y" && (
              <InputWrapper>
                <AutoCompleteSelect
                  size='medium'
                  _key={"OPTION_KEY"}
                  options={financer || []}
                  getOptionLabel={(item) => item.OPTION_VAL || ""}
                  label={"Select your Financier"}
                  selectedValue={
                    formData?.financier_val && {
                      OPTION_KEY: formData?.financier_val,
                      OPTION_VAL: formData?.financier_name,
                    }
                  }
                  getOptionSelected={(item, value) =>
                    item?.OPTION_KEY === value?.OPTION_KEY
                  }
                  handleChange={(val) => {
                    onChange("financier_val", val.OPTION_KEY);
                    onChange("financier_name", val.OPTION_VAL);
                  }}
                  error={errors?.prev_insurer_val}
                />
              </InputWrapper>
            )}
          </>
        )}
      </ClosedForm>
    </>
  );
};

export default VehicleDetails;
