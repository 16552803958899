import { useState } from "react";
import { useSelector } from "react-redux";
import AutoComplete from "../../../../../../components/AutoCompleteSelect/AutoComplete";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import { useFinalSubmitMutation, useProposalEnquiry } from "../../../../../../services/ProposalServices";
import { onInputPhone, uppercase } from "../../../../../../utils/onInput";
import { yesNoItems } from "../../../../../../utils/YesNoRadioItems";
import { InputWrapper } from "../../../../ProposalForm.style";
import ClosedForm from "../ClosedForm/ClosedForm";
import validation from "./ckycDetailsValidation";
import moment from "moment";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";

const CKYCDetails = () => {
  const { proposalData } = useSelector((state) => state.proposal);
  const [errors, setErrors] = useState({});
  const { product } = useProposalEnquiry();
  const [formData, setFormData] = useState({
    ckyc_no:proposalData?.ckyc_no,
    ckyc_dob:proposalData?.ckyc_dob,
    ckyc_pan_no: proposalData?.ckyc_pan_no,
    pan_dob: proposalData?.pan_dob,
    aadhaar_number: proposalData?.aadhaar_number,
    full_name: proposalData?.full_name,
    aadhaar_dob: proposalData?.aadhaar_dob,
    ckyc_gender: proposalData?.ckyc_gender,
    dl_number: proposalData?.dl_number,
    dl_dob: proposalData?.dl_dob,
    file_number: proposalData?.file_number,
    passport_dob: proposalData?.passport_dob,
    // method_of_verification: "", 
  });

  const { finalSubmit } = useFinalSubmitMutation();

  const onChange = async (name, data) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
    validation
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };

  const onSubmit = (onSuccess = () => {}) => {
    validation
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...proposalData, ...formData }, { isFormData: true });
      })
      .catch((error) => {
        error?.inner?.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };

  return (
    <>
      <ClosedForm
        title={"CKYC Details"}
        index={4}
        next={"Final Submit"}
        onSubmit={onSubmit}
        finalSubmit={finalSubmit}
        isFormData
      >
        <InputWrapper>
          <RadioButton
            items={yesNoItems}
            label='Do you have CKYC no.?'
            onChange={(val) => {
              onChange("is_ckyc_no", val);
            }}
            defaultValue={formData?.is_ckyc_no}
          />
        </InputWrapper>

        {formData?.is_ckyc_no === "Y" && (
          <>
          <InputWrapper>
          <TextInput
            size={"medium"}
            label={"CKYC Number"}
            error={errors?.ckyc_no}
            onInput={onInputPhone}
            maxLength={14}
            onChange={(e) => {
              onChange("ckyc_no", e.target.value);
            }}
            value={formData?.ckyc_no}
          />
        </InputWrapper>
        <InputWrapper>
                    <DatePicker
                      label={"ckyc dob"}
                      size='medium'
                      maxDate={moment().subtract(18, "years")}
                      minDate={moment().subtract(60, "years")}
                      handleChange={(val) => {
                        console.log(val); 
                        onChange("ckyc_dob", val);
                      }}
                      readOnly={proposalData?.ckyc}
                      outputFormat='YYYY-MM-DD'
                      value={formData?.ckyc_dob}
                      error={errors?.ckyc_dob}
                    />
                  </InputWrapper>
                  </>
        )} 

{formData?.is_ckyc_no === "N" && product?.INSURANCE_SLUG === "icici_lombard_general" && (
  <>
    <InputWrapper>
      <AutoComplete
        size="medium"
        label="Method of Verification"
        error={errors?.method_of_verification}
        handleChange={(val) => {
          onChange("method_of_verification", val.OPTION_KEY);
        }}
        _key="OPTION_VAL"
        options={verification_methods_icici}
        selectedValue={
          formData?.method_of_verification && {
            OPTION_KEY: formData?.method_of_verification,
            OPTION_VAL: verification_methods_icici?.find(
              (item) => item.OPTION_KEY === formData?.method_of_verification
            )?.OPTION_VAL,
          }
        }
        getOptionLabel={(item) => item.OPTION_VAL || ""}
        getOptionSelected={(item, val) => item.OPTION_KEY === val.OPTION_KEY}
      />
    </InputWrapper>

    {formData.method_of_verification === "pancard" && (
      <>
        <InputWrapper>
          <TextInput
            size="medium"
            label="Pan Number"
            error={errors?.ckyc_pan_no}
            onInput={uppercase}
            onChange={(e) => {
              onChange("ckyc_pan_no", e.target.value);
            }}
            maxLength={10}
            value={formData?.ckyc_pan_no}
          />
        </InputWrapper>
        <InputWrapper>
          <DatePicker
            label="Date of Birth"
            size="medium"
            maxDate={moment().subtract(18, "years")}
            minDate={moment().subtract(60, "years")}
            handleChange={(val) => {
              onChange("pan_dob", val);
            }}
            readOnly={proposalData?.ckyc}
            outputFormat="YYYY-MM-DD"
            value={formData?.pan_dob}
            error={errors?.pan_dob}
          />
        </InputWrapper>
      </>
    )}

    {formData.method_of_verification === "aadharcard" && (
      <>
        <InputWrapper>
          <TextInput
            size="medium"
            label="Aadhar Number"
            error={errors?.aadhaar_number}
            onChange={(e) => {
              onChange("aadhaar_number", e.target.value);
            }}
            maxLength={12}
            value={formData?.aadhaar_number}
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            size="medium"
            label="Aadhar Name"
            error={errors?.full_name}
            onChange={(e) => {
              onChange("full_name", e.target.value);
            }}
            maxLength={56}
            value={formData?.full_name}
          />
        </InputWrapper>
        <InputWrapper>
          <DatePicker
            label="Date of Birth"
            size="medium"
            maxDate={moment().subtract(18, "years")}
            minDate={moment().subtract(60, "years")}
            handleChange={(val) => {
              onChange("aadhaar_dob", val);
            }}
            readOnly={proposalData?.ckyc}
            outputFormat="YYYY-MM-DD"
            value={formData?.aadhaar_dob}
            error={errors?.aadhaar_dob}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            label="Gender"
            items={genderOptions}
            defaultValue={formData?.ckyc_gender}
            onChange={(val) => onChange("ckyc_gender", val)}
          />
        </InputWrapper>
      </>
    )}

    {formData.method_of_verification === "drivingdetails" && (
      <>
        <InputWrapper>
          <TextInput
            size="medium"
            label="Driving License Number"
            error={errors?.dl_number}
            onChange={(e) => {
              onChange("dl_number", e.target.value);
            }}
            maxLength={16}
            value={formData?.dl_number}
          />
        </InputWrapper>
        <InputWrapper>
          <DatePicker
            label="Driving License Date of Birth"
            size="medium"
            maxDate={moment().subtract(18, "years")}
            minDate={moment().subtract(60, "years")}
            handleChange={(val) => {
              onChange("dl_dob", val);
            }}
            readOnly={proposalData?.ckyc}
            outputFormat="YYYY-MM-DD"
            value={formData?.dl_dob}
            error={errors?.dl_dob}
          />
        </InputWrapper>
      </>
    )}

    {formData.method_of_verification === "passportdetails" && (
      <>
        <InputWrapper>
          <TextInput
            size="medium"
            label="Passport Number"
            error={errors?.file_number}
            onChange={(e) => {
              onChange("file_number", e.target.value);
            }}
            maxLength={12}
            value={formData?.file_number}
          />
        </InputWrapper>
        <InputWrapper>
          <DatePicker
            label="Passport Date of Birth"
            size="medium"
            maxDate={moment().subtract(18, "years")}
            minDate={moment().subtract(60, "years")}
            handleChange={(val) => {
              onChange("passport_dob", val);
            }}
            readOnly={proposalData?.ckyc}
            outputFormat="YYYY-MM-DD"
            value={formData?.passport_dob}
            error={errors?.passport_dob}
          />
        </InputWrapper>
      </>
    )}
  </>
)}
      </ClosedForm>
    </>
  );
};

export default CKYCDetails;

const verification_methods = [
  {
    OPTION_KEY: "pan",
    OPTION_VAL: "PAN Number",
  },
  {
    OPTION_KEY: "doc",
    OPTION_VAL: "Physical Documents",
  },
];

const verification_methods_icici = [
  {
    OPTION_KEY: "pancard",
    OPTION_VAL: "Pancard Details",
  },
  {
    OPTION_KEY: "aadharcard",
    OPTION_VAL: "Aadhar Details",
  },
  {
    OPTION_KEY: "drivingdetails",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "passportdetails",
    OPTION_VAL: "Passport Details",
  },
]
const documents = [
  {
    OPTION_KEY: "D20",
    OPTION_VAL: "CGHS ECHS CARD",
  },
  {
    OPTION_KEY: "D21",
    OPTION_VAL: "PHOTO CREDIT CARD",
  },
  {
    OPTION_KEY: "D22",
    OPTION_VAL: "IT PROOF",
  },
  {
    OPTION_KEY: "D23",
    OPTION_VAL: "PHOTO PENSIONER CARD",
  },
  {
    OPTION_KEY: "D24",
    OPTION_VAL: "BANK STATEMENT",
  },
  {
    OPTION_KEY: "D25",
    OPTION_VAL: "AADHAAR IMAGE",
  },
  {
    OPTION_KEY: "D26",
    OPTION_VAL: "DRIVING LICENSE IMAGE",
  },
  {
    OPTION_KEY: "D27",
    OPTION_VAL: "VOTER ID IMAGE",
  },
  {
    OPTION_KEY: "D28",
    OPTION_VAL: "PASSPORT IMAGE",
  },
];

const genderOptions = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];
