import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/Button/Button";
import Check from "../../../../components/Checkbox/Checkbox";
import {
  FormCard,
  InputWrapper,
  ProposalFormWrapper,
} from "../../ProposalForm.style";
import CarOwnerDetails from "./components/CarOwnerDetails/CarOwnerDetails";
import CommunicationAddress from "./components/CommunicationAddress/CommunicationAddress";
import PersonalDetails from "./components/PersonalDetails/PersonalDetails";
import VehicleDetails from "./components/VehicleDetails/VehicleDetails";
import "styled-components/macro";
import {
  useFinalSubmitMutation,
  useProposalEnquiry,
} from "../../../../services/ProposalServices";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import DigitCKYCDetails from "./components/CKYCDetails/DigitCKYCDetails";
const ProposalForm = () => {
  const { activeIndex, proposalData } = useSelector((state) => state.proposal);
  const [checked, setChecked] = useState(false);
  const { product } = useProposalEnquiry();
  return (
    <>
      <ProposalFormWrapper>
        <CarOwnerDetails />
        {proposalData.owner_type !== "COMP" && <PersonalDetails />}
        <CommunicationAddress />
        <VehicleDetails />
        {(product?.INSURANCE_SLUG === "godigit" || "icici_lombard_general" ) && <DigitCKYCDetails />}
      </ProposalFormWrapper>
    </>
  );
};

export default ProposalForm;
