import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Edit, FormCard, FormTitle } from "../../../../ProposalForm.style";
import "styled-components/macro";
import { Button } from "../../../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  goNextIndex,
  setActiveIndex,
  setProposalData,
} from "../../../../Proposal.slice";
import {
  useProposalEnquiry,
  useUpdateProposalMutation,
  useVerifyPanCKYC,
} from "../../../../../../services/ProposalServices";
import useUrlQuery from "../../../../../../hooks/useUrlQuery";
const ClosedForm = ({
  children,
  title,
  next,
  index,
  onSubmit,
  finalSubmit,
  isFormData,
}) => {
  const { activeIndex } = useSelector((state) => state.proposal);
  const [formStatus, setFormStatus] = useState();
  const { get } = useUrlQuery();
  const PROPOSAL_ID = get("proposal_id");
  const { product } = useProposalEnquiry();
  const { submit, asyncSubmit, isLoading, isSuccess } =
    useUpdateProposalMutation(isFormData);
  const { verifyCKYC } = useVerifyPanCKYC();
  const dispatch = useDispatch();
  useEffect(() => {
    if (activeIndex === index) setFormStatus("edit");
    else if (activeIndex > index) setFormStatus("preview");
    else setFormStatus("close");
  }, [activeIndex, index]);
  useEffect(() => {
    if (isSuccess && finalSubmit) {
      finalSubmit();
    }
  }, [isSuccess]);
  return (
    <>
      <FormCard padding={formStatus === "preview"}>
        <FormTitle>{title}</FormTitle>
        {formStatus === "preview" && (
          <Edit
            onClick={() => {
              dispatch(setActiveIndex(index));
            }}
          >
            Edit
          </Edit>
        )}
        {
          <>
            {formStatus !== "close" && (
              <>
                {" "}
                <Divider />
                <div
                  css={`
                    padding: 26px 0;
                  `}
                >
                  {children}
                  {formStatus === "edit" && (
                    <Button
                      css={`
                        width: 70%;
                        margin: 48px auto 0;
                        @media (max-width: 991px) {
                          width: 100%;
                          margin: 30px auto 0;
                          padding: 12px 0;
                        }
                      `}
                      primary
                      onClick={() => {
                        onSubmit(async (formData, options) => {
                          if (
                            activeIndex === 0 &&
                            (product?.INSURANCE_SLUG === "hdfc_ergo" ||
                              product?.INSURANCE_SLUG === "reliance")
                          ) {
                            await asyncSubmit(formData, options);
                            const panStatus = await verifyCKYC({
                              pan_no: formData?.pan_no,
                              PROPOSAL_ID,
                            });
                            if (
                              panStatus?.data?.CKYC_LINK &&
                              panStatus?.data?.IS_CKYC === "N"
                            ) {
                              window.open(panStatus?.data?.CKYC_LINK, "_self");
                            }
                          } else submit(formData, options);
                          dispatch(setProposalData(formData));
                        });
                      }}
                    >
                      Continue To {next}
                    </Button>
                  )}
                </div>
              </>
            )}
          </>
        }
      </FormCard>
    </>
  );
};

export default ClosedForm;
